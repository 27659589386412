import { Marquee, loop } from 'dynamic-marquee'
import Scroller from '@newswire/scroller'

window.addEventListener('DOMContentLoaded', (event) => {
  const $main = document.querySelector('main')
  const $items = document.querySelectorAll('.item')
  const $firstItem = $items[0]
  const $lastItem = $items[$items.length - 1]

  const scroller = new Scroller({
    container: document.querySelector('main'),
    scenes: document.querySelectorAll('.item'),
  })

  scroller.on('scene:enter', (d) => {
    d.element.style.setProperty('--transform', d.isScrollingDown ? '-100px' : '100px')
    d.element.classList.add('active')
  })

  scroller.on('scene:exit', (d) => {
    d.element.style.setProperty('--transform', d.isScrollingDown ? '100px' : '-100px')
    d.element.classList.remove('active')
  })

  scroller.init()

  document.querySelectorAll('.marquee').forEach(($el) => {
    let marquee = new Marquee($el, {
      rate: -20, // 20 pixels/s
      startOnScreen: true,
    })

    loop(
      marquee,
      [
        function () {
          return 'COMING SOON'
        },
      ],
      function () {
        var $separator = document.createElement('div')
        $separator.innerHTML = '&nbsp;&nbsp;&nbsp;'
        return $separator
      },
    )
  })

  window.addEventListener('scroll', (event) => {
    if (window.scrollY > $main.offsetTop - 30) {
      document.body.classList.add('scrolled')
    } else {
      document.body.classList.remove('scrolled')
    }

    if (window.scrollY > $firstItem.offsetTop) {
      $firstItem.classList.add('reached')
    } else {
      $firstItem.classList.remove('reached')
    }

    if (window.scrollY > $lastItem.offsetTop) {
      $lastItem.classList.add('reached')
    } else {
      $lastItem.classList.remove('reached')
    }
  })
})
